import React from 'react';
import Header from './Header/Header';
import Hero from './Hero/Hero';
import About from './About/About';
import Services from './Services/Services';
import Contact from './Contact/Contact';
import Footer from './Footer/Footer';
import './app.css';
import Properties from './Properties/Properties';

function App() {
  return (
    <>
      <Header />
      <Hero />
      <main id="main">
        <About />
        <Services />
        <Properties />
        <Contact />
      </main>
      <Footer />
    </>
  );
}

export default App;
