import React from 'react';
import './properties.css';
import Property1 from './images/1.jpg';
import Property2 from './images/2.jpg';
import Property3 from './images/3.jpg';
import Property4 from './images/4.jpg';
import Property5 from './images/5.jpg';
import Property6 from './images/6.jpg';
import Property from './Property';

const Properties = () => (
  <section className="property-grid grid properties">
    <div className="container">
      <div className="section-title">
        <h2>Latest Properties</h2>
      </div>
      <div className="row">
        <Property name="Property 1" image={Property1} price="582 400" />
        <Property name="Property 2" image={Property2} price="902 100" />
        <Property name="Property 3" image={Property3} price="222 400" />
        <Property name="Property 4" image={Property4} price="700 610" />
        <Property name="Property 5" image={Property5} price="882 179" />
        <Property name="Property 6" image={Property6} price="122 670" />
      </div>

    </div>
  </section>
);

export default Properties;
