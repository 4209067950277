import React from 'react';
import Zoom from 'react-reveal/Zoom';
import PropTypes from 'prop-types';

const Property = ({ name, image, price }) => (
  <Zoom>
    <div className="col-md-4">
      <div className="card-box-a card-shadow">
        <div className="img-box-a">
          <img src={image} alt="" className="img-a img-fluid" />
        </div>
        <div className="card-overlay">
          <div className="card-overlay-a-content">
            <div className="card-header-a">
              <h2 className="card-title-a">
                {name}
              </h2>
            </div>
            <div className="card-body-a">
              <div className="price-box d-flex">
                <span className="price-a">
                  from | R
                  {' '}
                  {price}
                </span>
              </div>
            </div>
            <div className="card-footer-a">
              <p className="card-info d-flex justify-content-around card-p">
                Residential Property investment company,
                that buys and builds residential property in fast growing areas.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Zoom>
);
Property.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
};
export default Property;
