import React, { useState, useEffect } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import './header.css';
import Container from 'react-bootstrap/Container';
import { Link, animateScroll as scroll } from 'react-scroll';
import logo from './logo.png';

const scrollToTop = () => {
  scroll.scrollToTop();
};

const Header = () => {
  let listener = null;
  const [isTop, setIsTop] = useState(false);
  useEffect(() => {
    listener = document.addEventListener('scroll', () => {
      const scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 120) {
        if (!isTop) {
          setIsTop(true);
        }
      } else if (isTop) {
        setIsTop(false);
      }
    });
    return () => {
      document.removeEventListener('scroll', listener);
    };
  }, [isTop]);
  return (
    <header id="header" className={`fixed-top header-transparent ${isTop && 'header-scrolled'}`}>
      <Navbar collapseOnSelect expand="lg" variant="dark">
        <Container>
          <Navbar.Brand className="logo" href="#home" onClick={scrollToTop}><img src={logo} alt="logo" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" className="nav-menu justify-content-end">
            <Nav defaultActiveKey="#hero" as="ul">
              <Nav.Item as="li">
                <Link
                  activeClass="active"
                  to="hero"
                  spy
                  smooth
                  offset={-70}
                  duration={750}
                >
                  Home
                </Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Link
                  activeClass="active"
                  to="about"
                  spy
                  smooth
                  offset={-70}
                  duration={750}
                >
                  About
                </Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Link
                  activeClass="active"
                  to="services"
                  spy
                  smooth
                  offset={-70}
                  duration={750}
                >
                  What we do
                </Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Link
                  activeClass="active"
                  to="properties"
                  spy
                  smooth
                  offset={-70}
                  duration={750}
                >
                  Properties
                </Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Link
                  activeClass="active"
                  to="contact"
                  spy
                  smooth
                  offset={-70}
                  duration={750}
                >
                  Contact
                </Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>

  );
};

export default Header;
