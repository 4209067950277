import React from 'react';
import Zoom from 'react-reveal/Zoom';
import PropTypes from 'prop-types';
import './services.css';

const Service = ({
  name, description, number,
}) => (
  <Zoom left>
    <div className="col-lg-6 col-md-6 content-item">
      <span>{number}</span>
      <h4>{name}</h4>
      <p>
        {description}
      </p>
    </div>
  </Zoom>
);

Service.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
};
export default Service;
