import React from 'react';
import { BiRun, BiBullseye } from 'react-icons/bi';
import Zoom from 'react-reveal/Zoom';
import './about.css';
import aboutImg from './about.jpg';

const About = () => (
  <section id="about" className="about">
    <div className="container">

      <div className="row">
        <div className="col-lg-6">
          <Zoom left>
            <img src={aboutImg} className="img-fluid" alt="" />
          </Zoom>
        </div>
        <div className="col-lg-6 pt-4 pt-lg-0">
          <Zoom right>
            <div className="about-title">
              <h2>About Us</h2>
              <p>
                Residential Property investment company, that buys
                and builds residential property in fast growing areas.

              </p>
            </div>
            <div className="row">
              <div className="col-md-6">
                <BiRun />
                <h4>Mission</h4>
                <p>
                  To provide value for money, safe and central residential property.
                </p>
              </div>
              <div className="col-md-6">
                <BiBullseye />
                <h4>Vision</h4>
                <p>
                  To provide value for money, safe and central residential property.

                </p>
              </div>
            </div>
          </Zoom>
        </div>
      </div>

    </div>
  </section>
);

export default About;
