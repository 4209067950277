import React from 'react';
import './footer.css';

const Footer = () => (
  <footer id="footer">
    <div className="container d-md-flex py-4 justify-content-center">
      <div className=" text-center text-md-start">
        <div className="copyright">
          &copy;
          {' '}
          {(new Date().getFullYear())}
          {' '}
          Copyright
          {' '}
          <strong>Vestrotrim</strong>
          . All Rights Reserved.
        </div>
        <div className="credits">
          Developed by
          {' '}
          <a href="https://yelah.co.za/">
            <strong>Yelah</strong>
            .
          </a>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
