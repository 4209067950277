import React from 'react';
import Zoom from 'react-reveal/Zoom';
import { Link } from 'react-scroll';
import './hero.css';

const Hero = () => (
  <section id="hero" className="d-flex align-items-center justify-content-center">
    <Zoom>
      <div className="container position-relative">
        <h1>Welcome to Vestrotrim</h1>
        <h2>
          Residential Property investment company, that buys and builds residential property in fast
          growing areas.
        </h2>
        <Link
          to="about"
          smooth
          offset={-70}
          duration={750}
          className="btn-get-started"
        >
          Get Started
        </Link>
      </div>
    </Zoom>

  </section>
);

export default Hero;
