import React from 'react';
import './services.css';
import Service from './Service';

const Services = () => (
  <section className="services">

    <div className="container">

      <div className="section-title">
        <h2>What we do</h2>
      </div>
      <div className="row ">

        <Service
          name="Property Development"
          description="a short description of service offered goes here."
          number="01"
        />
        <Service
          name="Rentals"
          description="a short description of service offered goes here."
          number="02"
        />
      </div>
    </div>
  </section>
);

export default Services;
