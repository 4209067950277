import React from 'react';
import { BiMap, BiEnvelope, BiPhoneCall } from 'react-icons/bi';
import Zoom from 'react-reveal/Zoom';
import './contact.css';

const Contact = () => (
  <section id="contact" className="contact">
    <div className="container">
      <div className="section-title">
        <h2>Contact</h2>
        <p>
          Want to visit our office or call us ? Or maybe email us ? we are here for you.
        </p>
      </div>

      <div className="row">
        <div className="col-md-4">
          <Zoom top>
            <div className="info-box mt-4">
              <BiMap />
              <h3>Our Address</h3>
              <p>1st Floor, Gateway West, 22 Magwa Cres, Waterval City, Johannesburg, 2066</p>
            </div>
          </Zoom>
        </div>
        <div className="col-md-4">
          <Zoom left>
            <div className="info-box mt-4">
              <BiEnvelope />
              <h3>Email Us</h3>
              <p>
                info@vestrotrim.co.za
                <br />
                contact@vestrotrim.co.za
              </p>
            </div>
          </Zoom>
        </div>
        <div className="col-md-4">
          <Zoom right>
            <div className="info-box mt-4">
              <BiPhoneCall />
              <h3>Call Us</h3>
              <p>
                +27 42 331 4444
                <br />
                +27 13 551 8884
              </p>
            </div>
          </Zoom>
        </div>

      </div>
    </div>
  </section>
);

export default Contact;
